import React from "react";
import { injectIntl } from "gatsby-plugin-intl";

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Section from "../components/section";
import ContactForm from '../components/contact-form';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

import "../styles/contact.less";
import params from "../data/params";


class Contact extends React.Component
{
  // Los componentes funcionales no tienen estado (también se les llama stateless components), por eso a veces uso la sintaxis de clase
  // Hay que definir el estado y su valor inicial en el constructor mediante this.state = {...}
  // La última sentencia en el constructor es para que el método displayFlashNotification tenga una referencia correcta de la variable 'this'.

  constructor(props)
  {
    super(props);
    this.state = {displayFailFlash: false, displayOkFlash: false};

    this.displayFlashNotification = this.displayFlashNotification.bind(this);
  }

  // Esta función se define aquí pero se ejecuta desde el componente ContactForm, se le pasa como parámetro en el render.
  displayFlashNotification = (status) =>
  {
    document.getElementById('notification-box').classList.remove('alert-danger');
    document.getElementById('notification-box').classList.remove('alert-success');

    if (status === 'err')
    {
      document.getElementById('notification-box').classList.add('alert-danger');
      this.setState({displayFailFlash: true});
    }

    if (status === 'ok')
    {
      document.getElementById('notification-box').classList.add('alert-success');
      this.setState({displayOkFlash: true});
    }

    var button = document.querySelector('.form-trigger button');
    button.disabled = false;

    setTimeout(() => {
      this.setState({displayFailFlash: false, displayOkFlash: false})
      document.getElementById('notification-box').classList.remove('alert-danger');
      document.getElementById('notification-box').classList.remove('alert-success');
    }, 5000);
  }

  render()
  {
    return <Layout staticHeader>
      <SEO 
        title={this.props.intl.formatMessage({id: "Contact - Adventurees Alliance"})} 
        description={this.props.intl.formatMessage({id: "Contact us and we will answer all your questions"})}
        location={this.props.location}
      />

      <div className="container">
        <div className="row">
          <div id="notification-box" className="col-md-12 notification-box alert">
            {this.state.displayFailFlash && <p>Ha fallado el envío del formulario</p>}
            {this.state.displayOkFlash && <p>Su mensaje ha sido enviado correctamente</p>}
          </div>
        </div>
      </div>
      <Section title={this.props.intl.formatMessage({id: "Want to know more?"})} subtitle={this.props.intl.formatMessage({id: "Contact us!"})} className="contact-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <ContactForm displayFlashNotification={this.displayFlashNotification.bind(this)}/>
            </div>
            <div className="col-lg-4">
              <div className="contact-info">
                <h3 className="title">{this.props.intl.formatMessage({id: "Contact information"})}</h3>
                <ul>
                  <li><FontAwesomeIcon icon={faMapMarkerAlt}></FontAwesomeIcon>Paseo de la Castellana, 200, 28046, Madrid</li>
                  <li><FontAwesomeIcon icon={faPhone}></FontAwesomeIcon><a href="tel:(+34) 911 930 061">(+34) 911 930 061</a></li>
                  <li><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon><a href={"mailto://"+params.CONTACT_EMAIL}>{params.CONTACT_EMAIL}</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </Layout>;
  }
}

export default injectIntl(Contact);
